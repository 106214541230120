<template>
  <div class="auth ngo">
    <div class="ngo-block">
      <div class="ngo-wrap">
        <img width="60" src="/images/ngo.svg">
      </div>
      <div class="ngo-title">Select your NGO</div>
      <div class="text">Which NGO did you sign up with? This will be <strong>your bread delivery point</strong>. Please note you currently won’t be able to change your NGO after this.</div>
      <div class="selection-wrap">
        <div class="selection-inner">
          <div class="selection-list" ref="selection" :style="styles">
            <div class="select-item disabled" />
            <div class="select-item"
                 v-for="(ngo, index) in $store.state.ngos.all"
                 :key="index"
                 v-touchevents:swipe="swipeHandler"
                 v-touchevents:tap="selectNgo(ngo)"
            >
              <div class="select-inner"
                   :class="{'active':selectedNgos.length ? selectedNgos[0].id === ngo.id : null}"
              >
                <div class="top-text">Drop off point</div>
                <div class="img-wrap" :class="{'first':index === 0}">
                  <img :src="`/images/ngos/${ngo.code.toLowerCase() }.png`" draggable="false">
                </div>
                <div class="name">{{ ngo.nameEn }}</div>
                <div class="location">{{ ngo.defaultDropOffAddressEn }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="action">
        <v-btn @click="confirm" class="btn-confirm" large rounded width="280" :disabled="!selectedNgos.length">Confirm</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as userService from '@/services/user';

export default {
  metaInfo: {
    title: 'Ngos'
  },
  data () {
    return {
      selected: false,
      styles: {},
      selectedNgos: [],
      count: 0
    };
  },
  created () {
    this.$store.dispatch('ngos/get');
  },
  methods: {
    swipeHandler (direction) {
      if (direction === 'right' || direction === 'top') {
        if (this.count === 0) {
          this.count = 0;
        } else {
          this.count--;
        }
      } else {
        if (this.count === this.$store.state.ngos.all.length - 1) {
          this.count = this.$store.state.ngos.all.length - 1;
        } else {
          this.count++;
        }
      }
      let itemWidth = this.$refs.selection.children[1].clientWidth;
      this.styles = { transform: 'translateX(-' + (itemWidth * this.count) + 'px)' };
    },
    selectNgo (ngo) {
      return () => {
        this.selectedNgos = [ngo];
      };
    },
    confirm () {
      userService.updateNgos(this.selectedNgos.map(ngo => ngo.id))
        .then(() => {
          this.$router.push({ name: 'phone' });
        });
    }
  }
};
</script>
